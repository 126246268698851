import {
  type PlayerSelfExclusionPayload,
  SelfExclusionType,
} from '~/@types/generated/backend/graphql-schema-types'

const getNavigation = (selfExclusion: PlayerSelfExclusionPayload) => {
  switch (selfExclusion.type) {
    case SelfExclusionType.CoolOff:
      return '/cool-off'
    case SelfExclusionType.SelfExclusion:
      return '/self-excluded'
  }
}

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return
  const { isLoggedIn } = useAuth()
  if (!isLoggedIn.value) return

  const payload = await useUserStatusLazyQuery({
    fetchPolicy: 'cache-first',
  }).load()

  if (!payload || !payload?.player) {
    const { log } = useDatadog()
    log('info', {
      component: 'self-excluded.ts',
      message: 'Payload or player is missing',
    })
    return
  }

  const nuxtApp = useNuxtApp()
  const { selfExclusion } = payload.player
  if (!selfExclusion) return

  const path = getNavigation(selfExclusion)
  if (nuxtApp.isHydrating) {
    onNuxtReady(() => navigateTo(path))
  } else {
    return path
  }
})
